.footer {
  padding-top: 4.5rem;
  padding-bottom: 2.5rem;

  @media (max-width: $breakpoint-tablet) {
    padding: 2.4rem 0;
  }
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.2rem;
    @media (max-width: $breakpoint-mobile) {
      gap: 1rem;
      flex-direction: column;
    }
  }
  .logo {
    margin-bottom: 2rem;
    @media (max-width: $breakpoint-tablet) {
      margin-bottom: 1rem;
    }
  }

  &__link {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 150%;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    text-align: center;
    color: #666;
    display: block;

    &:hover {
      text-decoration: none;
    }
    @media (max-width: $breakpoint-mobile) {
      font-size: 1.4rem;
    }
    &:hover {
      text-decoration: none;
    }
  }
  &__copyright {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 150%;
    text-align: center;
    color: #666;
    opacity: 0.5;
  }
}
