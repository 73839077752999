.button {
  cursor: pointer;
  white-space: nowrap;
  border: none;
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 0rem 2rem;
  min-width: 25.6rem;
  height: 7rem;
  font-weight: 700;
  font-size: 2rem;
  color: #fff;
  @include tr;
  background: #e9400e;
  border-radius: 5.7rem;
  @media (max-width: $breakpoint-mobile) {
    width: 100%;
    padding: 1.5rem;
    max-width: 100%;
    height: 5rem;
    font-size: 1.6rem;
  }
  &:hover {
    box-shadow: 0 15px 54px 0 rgba(233, 64, 14, 0.3);
  }

  &_full {
    width: 100%;
    max-width: 100%;
  }

  &-order {
    height: 5rem;
    background: #f6efea;
    font-size: 1.6rem;
    color: $color-accent;
    font-weight: 400;
    padding: 0 2.5rem;
    &:hover {
      background: $color-primary;
      color: #fff;
    }
  }
}
