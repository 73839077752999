// $fa-font-path: "../webfonts";
// $font: 'Montserrat', sans-serif;
$font-family: 'Inter Tight', sans-serif;
// $second-family: 'Forum', serif;
// $third-family: 'Bad Script', cursive;

$color-text-dark: #0c0c0c;
$color-text-light: #ffffff;
$color-primary: #d18250;
$color-accent: #1d2d52;

// $color-second: #2383f7;
// $color-link: #2c2b3c;

// $primary-color: #f8bbd0 !default;
// $accent-color

// $color-border: #eff0f1;

// $color-input-border: #e4e6e9;
$breakpoint-tablet: 992px;
$breakpoint-mobile: 767px;
