.h1 {
  margin: 0;
  padding: 0;
  font-weight: 700;
  font-size: 5.5rem;
  color: #1e4389;
  margin-bottom: 4rem;
  @media (max-width: $breakpoint-mobile) {
    font-size: 3.2rem;
    margin-bottom: 3rem;
  }
}
.h2 {
  margin: 0;
  padding: 0;
  font-weight: 700;
  font-size: 3.5rem;
  color: #1e4389;
  margin-bottom: 2.8rem;
  @media (max-width: $breakpoint-mobile) {
    font-size: 2.4rem;
    margin-bottom: 2rem;
  }
}
.section-main {
  margin-bottom: 12rem;
  position: relative;
  z-index: 2;
  @media (max-width: $breakpoint-tablet) {
    margin-bottom: 4rem;
    display: flex;
    flex-direction: column-reverse;
    gap: 0rem;
  }
  @media (max-width: $breakpoint-tablet) {
    .container {
      width: 100%;
    }
  }
  &:before {
    content: '';
    display: block;
    width: 102.2rem;
    @include bg-img;
    height: 68.9rem;
    background-image: url(../img/top-img.png);
    position: absolute;
    top: -16rem;
    right: 0;
    z-index: 2;
    @media (max-width: 1455px) {
      width: 83.2rem;
      background-size: contain;
    }
    @media (max-width: 1220px) {
      width: 50rem;
    }
    @media (max-width: $breakpoint-tablet) {
      position: static;
      width: 100%;
    }
    @media (max-width: $breakpoint-mobile) {
      height: 30rem;
    }
  }
  &__img {
    background: url(../img/left-img_without.png) top left no-repeat;
    background-size: 65.9rem;
    height: 46.4rem;
    padding-left: 9rem;
    padding-top: 4rem;
    @media (max-width: $breakpoint-mobile) {
      width: 33rem;
      background-size: contain;
      height: 23.2rem;
      padding-left: 6rem;
      padding-top: 3rem;
    }
  }
  &__text {
    font-weight: 700;
    font-size: 5.5rem;
    color: #fff;
    @media (max-width: $breakpoint-mobile) {
      font-size: 2rem;
    }
  }
  &__text1 {
    transform: rotate(-2deg);
    margin-bottom: 8rem;
    @media (max-width: $breakpoint-mobile) {
      margin-bottom: 4rem;
    }
  }
  &__text2 {
    transform: rotate(0deg);
    margin-bottom: 6.6rem;
    @media (max-width: $breakpoint-mobile) {
      margin-bottom: 4rem;
    }
  }
  &__text3 {
    transform: rotate(1deg);
  }
}
.section-why {
  margin-bottom: 7rem;
  @media (max-width: $breakpoint-tablet) {
    margin-bottom: 4rem;
  }

  &__inner {
    display: flex;
    gap: 7rem;
    align-items: center;
    justify-content: space-between;
    position: relative;
    @media (max-width: $breakpoint-tablet) {
      flex-direction: column;
      gap: 3rem;
    }

    &:before {
      content: '';
      display: block;
      width: 76.7rem;
      height: 68.2rem;
      @include bg-img;
      background-image: url('data:image/svg+xml,<svg width="767" height="682" viewBox="0 0 767 682" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.1" d="M232.78 653.783C432.506 724.032 644.279 661.768 714.527 462.043C784.775 262.317 811.883 98.5358 612.157 28.2877C412.431 -41.9605 96.501 20.235 26.2529 219.961C-43.9952 419.687 33.0542 583.535 232.78 653.783Z" fill="url(%23paint0_radial_37_12)" /><defs><radialGradient id="paint0_radial_37_12" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(197.917 319.322) rotate(3.40494) scale(788.41 773.64)"><stop stop-color="%230047FF" /><stop offset="1" stop-color="%2386A8FF" /></radialGradient></defs></svg>');
      position: absolute;
      top: -16.4rem;
      left: -18.8rem;
      z-index: -1;
      @media (max-width: $breakpoint-mobile) {
        width: 35rem;
        height: 30rem;
        left: auto;
        right: 0;
        top: 2.6rem;
        background-size: contain;
      }
    }
  }
  &__items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 8.4rem;
    row-gap: 5.2rem;
    @media (max-width: $breakpoint-mobile) {
      gap: 3rem;
      grid-template-columns: 1fr;
    }
  }
  &__icon {
    height: 4.3rem;
    margin-bottom: 1.6rem;
  }
  &__name {
    font-weight: 700;
    font-size: 2.5rem;
    color: #1e4389;
    margin-bottom: 2.3rem;
    @media (max-width: $breakpoint-mobile) {
      font-size: 1.8rem;
      margin-bottom: 1.5rem;
    }
  }
  &__text {
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 150%;
    color: #687fab;
    @media (max-width: $breakpoint-mobile) {
      font-size: 1.4rem;
    }
    ul {
      padding-left: 2.5rem;
    }
  }
  &__img {
    flex: none;
    max-width: 62rem;
  }
}
.section-gallery {
  margin-bottom: 13rem;
  background-image: url('data:image/svg+xml,<svg width="867" height="680" viewBox="0 0 867 680" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.1" d="M178.08 546.544C327.232 696.806 544.973 733.051 695.235 583.899C845.497 434.746 941.267 299.147 792.115 148.884C642.962 -1.37817 223.186 -62.4049 72.924 86.7474C-77.3384 235.9 28.9276 396.281 178.08 546.544Z" fill="url(%23paint0_radial_38_22)" /><defs><radialGradient id="paint0_radial_38_22" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(292.451 230.317) rotate(29.2394) scale(788.41 773.64)"><stop stop-color="%230047FF" /><stop offset="1" stop-color="%2386A8FF" /></radialGradient></defs></svg>');
  background-repeat: no-repeat;
  background-position: top 6rem center;
  @media (max-width: $breakpoint-tablet) {
    margin-bottom: 4rem;
  }
  .h1 {
    text-align: center;
  }
  &__inner {
    position: relative;
  }
  &__prev,
  &__next {
    position: absolute;
    z-index: 2;
    top: 50%;
    margin-top: -5rem;
    left: 1.6rem;
    @include tr;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
  &__next {
    left: auto;
    right: 1.6rem;
  }
}
.section-services {
  margin-bottom: 10rem;
  @media (max-width: $breakpoint-tablet) {
    margin-bottom: 4rem;
  }
  .container {
    position: relative;

    &:before {
      content: '';
      display: block;
      width: 70.1rem;
      height: 61.1rem;
      @include bg-img;
      background-image: url('data:image/svg+xml,<svg width="701" height="611" viewBox="0 0 701 611" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M520.193 568.061C348.385 649.986 153.116 614.613 71.191 442.806C-10.7344 270.998 -50.6531 127.19 121.154 45.2646C292.962 -36.6608 581.33 -11.3245 663.256 160.483C745.181 332.29 692 486.135 520.193 568.061Z" fill="url(%23paint0_radial_46_9)"/><defs><radialGradient id="paint0_radial_46_9" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(519.321 265.744) rotate(170.479) scale(708.798 695.52)"><stop stop-color="%23E9400E"/></radialGradient></defs></svg>');
      position: absolute;
      top: 7.6rem;
      left: -15.8rem;
      z-index: -1;
      @media (max-width: $breakpoint-mobile) {
        width: 35rem;
        height: 30rem;
        left: auto;
        right: 0;
        top: 2.6rem;
        background-size: contain;
      }
    }
  }
  &__items {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-bottom: 5.3rem;
    @media (max-width: $breakpoint-mobile) {
      margin-bottom: 3rem;
    }
  }
  &__center {
    display: flex;
    align-items: center;
  }
  &__item {
    border-radius: 2rem;
    padding: 1.8rem 2.1rem;
    box-shadow: 0 1.5rem 6.4rem 0 rgba(0, 102, 255, 0.16);
    background: #fff;
    display: flex;
    align-items: center;
    gap: 4.4rem;
    @media (max-width: $breakpoint-mobile) {
      flex-direction: column;
      gap: 2rem;
    }
  }
  &__img {
    flex: none;
    @include wh(19.5rem);
    border-radius: 2rem;
    overflow: hidden;
    @media (max-width: $breakpoint-mobile) {
      width: 100%;
    }
    img {
      @include wh(100%);
      object-fit: cover;
    }
  }
  &__title {
    font-weight: 700;
    font-size: 2.5rem;
    color: #1e4389;
    margin-bottom: 1.1rem;
    @media (max-width: $breakpoint-mobile) {
      font-size: 1.8rem;
    }
  }
  &__text {
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 150%;
    color: #687fab;
    @media (max-width: $breakpoint-mobile) {
      font-size: 1.4rem;
    }
  }
  &__all {
    display: inline-flex;
    margin-left: auto;
    margin-right: auto;
    font-weight: 700;
    font-size: 2rem;
    text-align: center;
    color: #1e4389;
    padding-bottom: 3.6rem;
    @include tr;
    background-image: url('data:image/svg+xml,<svg width="28" height="16" viewBox="0 0 28 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 2L14 14L26 2" stroke="%23ACC4F1" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" /></svg>');
    background-position: center bottom 0rem;
    background-repeat: no-repeat;
    @media (max-width: $breakpoint-mobile) {
      font-size: 1.6rem;
      padding-bottom: 2rem;
    }
    &:hover {
      background-position: center bottom 1rem;
    }
  }
}

.section-about {
  margin-bottom: 14rem;
  @media (max-width: $breakpoint-tablet) {
    margin-bottom: 4rem;
  }
  &__inner {
    gap: 8rem;
    display: flex;
    align-items: center;
    position: relative;
    @media (max-width: $breakpoint-tablet) {
      gap: 3rem;
      flex-direction: column-reverse;
    }
    &:before {
      content: '';
      display: block;
      background-image: url('data:image/svg+xml,<svg width="560" height="499" viewBox="0 0 560 499" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M169.53 477.67C315.479 529.004 470.232 483.505 521.566 337.556C572.899 191.607 592.708 71.9248 446.759 20.5912C300.81 -30.7424 69.945 14.7067 18.6114 160.656C-32.7222 306.605 23.5815 426.337 169.53 477.67Z" fill="%23E9400E"/></svg>');
      @include bg-img;
      width: 56rem;
      height: 50rem;
      position: absolute;
      left: -8rem;
      top: -3rem;
      z-index: -1;
      @media (max-width: $breakpoint-mobile) {
        width: 28rem;
        height: 25rem;
        left: auto;
        top: auto;
        right: -2rem;
        bottom: 2rem;
        background-size: contain;
      }
    }
  }
  &__img {
    flex: none;
  }
  &__desc {
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 150%;
    color: #687fab;
    @media (max-width: $breakpoint-mobile) {
      font-size: 1.4rem;
    }
    p {
      margin-bottom: 1em;
    }
  }
}
.section-contacts {
  margin-bottom: 4.5rem;
  @media (max-width: $breakpoint-tablet) {
    margin-bottom: 4rem;
  }
  &__items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3.3rem;
    @media (max-width: $breakpoint-tablet) {
      grid-template-columns: 1fr;
      gap: 3rem;
    }
  }
  &__item {
    box-shadow: 0 1.5rem 6.4rem 0 rgba(0, 102, 255, 0.16);
    background: #fff;
    border-radius: 2rem;
    padding: 3.1rem 3.5rem;
    @media (max-width: $breakpoint-tablet) {
      padding: 2rem;
    }
  }
  &__svg {
    margin-bottom: 2.6rem;
    @media (max-width: $breakpoint-mobile) {
      margin-bottom: 1.5rem;
    }
  }
  &__desc {
    display: flex;
    gap: 5rem;
    align-items: flex-start;
    justify-content: space-between;
    @media (max-width: $breakpoint-mobile) {
      flex-direction: column;
      gap: 1rem;
    }
  }
  &__text {
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 150%;
    color: #687fab;
    max-width: 34rem;
    @media (max-width: $breakpoint-mobile) {
      font-size: 1.4rem;
    }
  }
  &__phones {
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 150%;
    color: #687fab;
    flex: none;
    @media (max-width: $breakpoint-mobile) {
      font-size: 1.4rem;
    }
  }
  &__phone,
  &__mail {
    display: block;
    a {
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 150%;
      color: #2663ff;
      @media (max-width: $breakpoint-mobile) {
        font-size: 1.4rem;
      }
    }
  }
}
.section-road {
  &__inner {
    display: flex;
    box-shadow: 0 1.5rem 6.4rem 0 rgba(0, 102, 255, 0.16);
    background: #fff;
    align-items: flex-start;
    padding: 2.1rem 4.3rem;
    border-radius: 2rem;
    gap: 2.5rem;
    align-items: flex-start;
    justify-content: space-between;
    @media (max-width: $breakpoint-tablet) {
      flex-direction: column;
      gap: 3rem;
      padding: 2rem;
    }
  }
  &__left {
    max-width: 40.6rem;
    flex: none;
  }
  &__contacts {
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 150%;
    color: #687fab;
    @media (max-width: $breakpoint-tablet) {
      flex-direction: column;
      font-size: 1.4rem;
      margin-bottom: 1.5rem;
    }
  }
  &__title {
    font-weight: 700;
  }
}
// .section-main {
//   margin-bottom: 7.6rem;
//   &__inner {
//     display: grid;
//     grid-template-columns: 1fr 1fr;
//     gap: 4.6rem;
//     @media (max-width: $breakpoint-tablet) {
//       grid-template-columns: 1fr;
//       gap: 3rem;
//     }
//   }
// }
// .main-block {
//   border-radius: 0.5rem;
//   background-position: center;
//   background-size: cover;
//   background-repeat: no-repeat;
//   min-height: 72rem;
//   display: flex;
//   flex-direction: column;
//   padding: 6.3rem 4.8rem 4rem 6.4rem;
//   @media (max-width: $breakpoint-tablet) {
//     min-height: 40rem;
//   }
//   @media (max-width: $breakpoint-mobile) {
//     padding: 3rem 2.4rem 3rem 2.4rem;
//   }
//   &_firm {
//     background-image: url(../img/main1.jpg);
//   }
//   &_cook {
//     background-image: url(../img/main2.jpg);
//   }
//   &__socials {
//     display: flex;
//     align-items: center;
//     gap: 3.5rem;
//     margin-bottom: 8.2rem;
//     @media (max-width: $breakpoint-tablet) {
//       margin-bottom: 3rem;
//     }
//     @media (max-width: $breakpoint-mobile) {
//       gap: 1.5rem;
//     }
//     a img {
//       @include tr;
//       &:hover {
//         opacity: 0.7;
//       }
//     }
//   }
//   &__title {
//     font-weight: 400;
//     font-size: 6.5rem;
//     line-height: 91%;
//     color: #fff;
//     // font-family: $second-family;
//     margin-bottom: 2.6rem;
//     @media (max-width: $breakpoint-mobile) {
//       font-size: 3.2rem;
//       margin-bottom: 1.5rem;
//     }
//   }
//   &__subtitle {
//     font-weight: 400;
//     font-size: 2.5rem;
//     line-height: 160%;
//     color: #fff;
//     // font-family: $third-family;
//     max-width: 36.9rem;
//     @media (max-width: $breakpoint-mobile) {
//       font-size: 1.8rem;
//     }
//   }
//   &__bottom {
//     display: flex;
//     width: 100%;
//     margin-top: auto;
//     justify-content: space-between;
//     align-items: flex-end;
//     gap: 2rem;
//     @media (max-width: $breakpoint-mobile) {
//       flex-direction: column;
//       justify-content: flex-start;
//       align-items: normal;
//       margin-top: 3rem;
//     }
//   }
//   &__left {
//     max-width: 26.5rem;
//   }
//   &__phone {
//     display: block;
//     padding-left: 2.6rem;
//     font-weight: 600;
//     font-size: 2.1rem;
//     color: #fff;
//     margin-bottom: 1.1rem;
//     background: url(../img/phone.svg) 0 0.2rem no-repeat;
//     background-size: 1.8rem;
//   }
//   &__address {
//     display: block;
//     padding-left: 2.6rem;
//     font-weight: 400;
//     font-size: 1.6rem;
//     color: #fff;
//     background: url(../img/pin.svg) 0 0.4rem no-repeat;
//     background-size: 1.8rem;
//   }
// }
